* {
	padding: 0;
	margin: 0;
}
/* body{
    font-family: 'Josefin Sans', sans-serif;
    background: url(image.jpg);
    background-position: center;
    background-size: cover;
} */
.form-box {
	width: 350px;
	margin: 100px auto 0;
	box-shadow: 0 0 10px 0 #000;
	padding: 58px 25px;
	background-color: snow;
	border-bottom-right-radius: 56px;
	border-top-left-radius: 56px;
}

.form-box h2 {
	font-weight: 400;
	text-align: center;
	margin: -80px auto 50px;
	background: snow;
	width: 200px;
	height: 40px;
	/* padding-top: 20px; */
	border-radius: 30px;
	box-shadow: 0 0 4px 0 #000;
}
.form-box p {
	text-align: center;
	margin: 30px auto;
}

.form-box input {
	width: 100%;
	height: 40px;
	padding: 26px;
	border-radius: 30px;
	border: 1px solid #999;
	outline: none;
	background: transparent;
}
.input-group {
	margin: 20px auto;
	position: relative;
}
.form-box label {
	position: absolute;
	top: -7px;
	left: 20px;
	background-color: snow;
	padding: 0 5px;
}
.form-box button {
	padding: 10px 18px;
	border: none;
	border-radius: 30px;
	font-size: 14px;
	background-color: #ff1100;
	color: #fff;
	cursor: pointer;
}
.form-box button:hover {
	background-color: black;
	transition: 0.6s;
}
.social {
	text-align: center;
}

.social img {
	width: 40px;
	margin: 0 6px;
	cursor: pointer;
}
