.card__container {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 100%;
	margin-top: 30px;
	h1 {
		width: 90%;
		text-align: left;
		text-transform: capitalize;
		font-size: 2.2vw;
		font-weight: 600;
		color: rgb(58, 58, 58);
	}
	&--inner {
		width: 94%;
		padding: 5px;
		display: block;
		&--card {
			padding: 10px 0;
			max-width: 347px;
			color: #fff;
			font-size: 50px;
			text-align: center;
			// background-color: #0a9e88;
			margin-left: 10px;
			transition: all 0.1s ease;

			h2 {
				color: rgb(58, 58, 58);
				font-size: 1.3vw;
				font-weight: 600;
				padding-left: 8px;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
				text-align: left;
				line-height: 1;
			}
			p {
				font-size: 1vw;
				font-weight: 400;
				color: rgb(58, 58, 58);
				text-align: left;
				padding-left: 8px;
				line-height: 0;
				span {
					color: rgb(58, 58, 58);
					font-size: 1.2vw;
					font-weight: 600;
					padding-left: 8px;
				}
			}
			img {
				width: 100%;
				height: 220px;
				border-radius: 1vw;
				overflow: hidden;
				display: flex;
				align-items: flex-end;
				-webkit-box-pack: center;
				justify-content: center;
				border-radius: 10px;
			}

			&:hover {
				transform: scale(1.05);
				cursor: pointer;
			}

			&--date_time {
				display: flex;
				align-items: center;
				margin-top: 5px;
				img {
					width: 20px;
					height: 20px;
					object-fit: contain;
				}
				p {
					color: #0a9e88;
					font-weight: bold;
				}
			}
		}
		&--card2 {
			padding: 10px 0;
			max-width: 200px;
			color: #fff;
			font-size: 50px;
			text-align: center;
			// background-color: #0a9e88;
			margin-left: 10px;
			transition: all 0.1s ease;

			h2 {
				color: rgb(58, 58, 58);
				font-size: 1.3vw;
				font-weight: 600;
				padding-left: 8px;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
				text-align: left;
				line-height: 1;
			}
			p {
				font-size: 1vw;
				font-weight: 400;
				color: rgb(58, 58, 58);
				text-align: left;
				padding-left: 8px;
				line-height: 0;
				span {
					color: rgb(58, 58, 58);
					font-size: 1.2vw;
					font-weight: 600;
					padding-left: 8px;
				}
			}
			img {
				width: 100%;
				height: 220px;
				overflow: hidden;
				display: flex;
				align-items: flex-end;
				-webkit-box-pack: center;
				justify-content: center;
				border-top-left-radius: 10px;
				border-top-right-radius: 10px;
			}

			&:hover {
				transform: scale(1.05);
				cursor: pointer;
			}

			&--date_time {
				display: flex;
				align-items: center;
				margin-top: 5px;
				img {
					width: 20px;
					height: 20px;
					object-fit: contain;
				}
				p {
					color: #0a9e88;
					font-weight: bold;
				}
			}
		}
		&--card3 {
			padding: 10px 0;
			max-width: 255px;
			color: #fff;
			font-size: 50px;
			text-align: center;
			// background-color: #0a9e88;
			margin-left: 10px;
			transition: all 0.1s ease;

			h2 {
				color: rgb(58, 58, 58);
				font-size: 1.3vw;
				font-weight: 600;
				padding-left: 8px;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
				text-align: left;
				line-height: 1;
			}
			p {
				font-size: 1vw;
				font-weight: 400;
				color: rgb(58, 58, 58);
				text-align: left;
				padding-left: 8px;
				line-height: 0;
				span {
					color: rgb(58, 58, 58);
					font-size: 1.2vw;
					font-weight: 600;
					padding-left: 8px;
				}
			}
			img {
				width: 100%;
				height: 340px;
				border-radius: 1vw;
				overflow: hidden;
				display: flex;
				align-items: flex-end;
				-webkit-box-pack: center;
				justify-content: center;
				border-radius: 10px;
			}

			&:hover {
				transform: scale(1.05);
				cursor: pointer;
			}

			&--date_time {
				display: flex;
				align-items: center;
				margin-top: 5px;
				img {
					width: 20px;
					height: 20px;
					object-fit: contain;
				}
				p {
					color: #0a9e88;
					font-weight: bold;
				}
			}
		}
	}
}

/* -------------------------------------------------------------- */
/* SLICK REACT CAROUSEL */
/* -------------------------------------------------------------- */
.slick-next,
.slick-prev {
	padding: 6px;
	width: 2vw;
	height: 2vw;
	cursor: pointer;
	border: none;
	border-radius: 5vw;
	background-color: #fff;
	z-index: 99 !important;
	box-shadow: rgb(35 35 35 / 50%) 0px 0px 20px 5px;
	top: 40%;
	&:hover {
		background-color: #fff;
	}
}

// .slick-next {
//   // margin-right: 3.2%;
// }
// .slick-prev {
//   // margin-left: .;
// }
*,*:before,*:after {
	box-sizing: border-box;
  }
 
  
  .plane {
	margin: 20px auto;
	max-width: 300px;
  }
  
  .cockpit {
	height: 250px; 
	position: relative;
	overflow: hidden;
	text-align: center;
	border-bottom: 5px solid #d8d8d8;
	&:before {
	  content: "";
	  display: block;
	  position: absolute;
	  top: 0;
	  left: 0;
	  height: 500px;
	  width: 100%;
	  border-radius: 50%;
	  border-right: 5px solid #d8d8d8;
	  border-left: 5px solid #d8d8d8;
	}
	h1 {
	  width: 60%;
	  margin: 100px auto 35px auto;
	}
  }
  
  .exit {
	position: relative;
	height: 50px;
	&:before,
	&:after {
	  content: "EXIT";
	  font-size: 14px;
	  line-height: 18px;
	  padding: 0px 2px;
	  font-family: "Arial Narrow", Arial, sans-serif;
	  display: block;
	  position: absolute;
	  background: green;
	  color: white;
	  top: 50%;
	  transform: translate(0, -50%);
	}
	&:before {
	  left: 0;
	}
	&:after {
	  right: 0;
	}
  }
  
  .fuselage {
	border-right: 5px solid #d8d8d8;
	border-left: 5px solid #d8d8d8;
  }
  
  ol {
	list-style :none;
	padding: 0;
	margin: 0;
  }
  
  
  
  .seats {
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: flex-start;  
  }
  
  .seat {
	display: flex;
	flex: 0 0 14.28571428571429%;
	padding: 5px;
	position: relative;  
	&:nth-child(3) {
	  margin-right: 14.28571428571429%;
	}
	input[type=checkbox] {
	  position: absolute;
	  opacity: 0;
	}
	input[type=checkbox]:checked {
	  + label {
		background: #bada55;      
		-webkit-animation-name: rubberBand;
			animation-name: rubberBand;
		animation-duration: 300ms;
		animation-fill-mode: both;
	  }
	}
	input[type=checkbox]:disabled {
	  + label {
		background: #dddddd;
		text-indent: -9999px;
		overflow: hidden;
		&:after {
		  content: "X";
		  text-indent: 0;
		  position: absolute;
		  top: 4px;
		  left: 50%;
		  transform: translate(-50%, 0%);
		}
		&:hover {
		  box-shadow: none;
		  cursor: not-allowed;
		}
	  }
	}
	label {    
	  display: block;
	  position: relative;    
	  width: 100%;    
	  text-align: center;
	  font-size: 14px;
	  font-weight: bold;
	  line-height: 1.5rem;
	  padding: 4px 0;
	  background: #F42536;
	  border-radius: 5px;
	  animation-duration: 300ms;
	  animation-fill-mode: both;
	  
	  &:before {
		content: "";
		position: absolute;
		width: 75%;
		height: 75%;
		top: 1px;
		left: 50%;
		transform: translate(-50%, 0%);
		background: rgba(255,255,255,.4);
		border-radius: 3px;
	  }
	  &:hover {
		cursor: pointer;
		box-shadow: 0 0 0px 2px #5C6AFF;
	  }
	  
	}
  }
  .seatss {
	display: flex;
	flex: 0 0 14.28571428571429%;
	padding: 5px;
	position: relative;  
	&:nth-child(3) {
	  margin-right: 14.28571428571429%;
	}
	// input[type=checkbox] {
	//   position: absolute;
	//   opacity: 0;
	// }
	input[type=checkbox]:checked {
	  + label {
		background: #F42536 ; 
		-webkit-animation-name: rubberBand;
			animation-name: rubberBand;
		animation-duration: 300ms;
		animation-fill-mode: both;
	  }
	}
	input[type=checkbox]:disabled {
	  + label {
		background: #dddddd;
		text-indent: -9999px;
		overflow: hidden;
		&:after {
		  content: "X";
		  text-indent: 0;
		  position: absolute;
		  top: 4px;
		  left: 50%;
		  transform: translate(-50%, 0%);
		}
		&:hover {
		  box-shadow: none;
		  cursor: not-allowed;
		}
	  }
	}
	input[type=checkbox] + label {    
	  display: block;
	  position: relative;    
	  width: 100%;    
	  text-align: center;
	  font-size: 12px;
	  font-weight: bold;
	  line-height: 1.5rem;
	  padding: 4px 0;
	  background: #bada55;
	  border-radius: 5px;
	  animation-duration: 300ms;
	  animation-fill-mode: both;
	  
	  &:before {
		content: "";
		position: absolute;
		width: 75%;
		height: 75%;
		top: 1px;
		left: 50%;
		transform: translate(-50%, 0%);
		background: rgba(255,255,255,.4);
		border-radius: 3px;
	  }
	  &:hover {
		cursor: pointer;
		box-shadow: 0 0 0px 2px #5C6AFF;
	  }
	  
	  
	}
  }
  
  @-webkit-keyframes rubberBand {
	0% {
	  -webkit-transform: scale3d(1, 1, 1);
			  transform: scale3d(1, 1, 1);
	}
  
	30% {
	  -webkit-transform: scale3d(1.25, 0.75, 1);
			  transform: scale3d(1.25, 0.75, 1);
	}
  
	40% {
	  -webkit-transform: scale3d(0.75, 1.25, 1);
			  transform: scale3d(0.75, 1.25, 1);
	}
  
	50% {
	  -webkit-transform: scale3d(1.15, 0.85, 1);
			  transform: scale3d(1.15, 0.85, 1);
	}
  
	65% {
	  -webkit-transform: scale3d(.95, 1.05, 1);
			  transform: scale3d(.95, 1.05, 1);
	}
  
	75% {
	  -webkit-transform: scale3d(1.05, .95, 1);
			  transform: scale3d(1.05, .95, 1);
	}
  
	100% {
	  -webkit-transform: scale3d(1, 1, 1);
			  transform: scale3d(1, 1, 1);
	}
  }
  
  @keyframes rubberBand {
	0% {
	  -webkit-transform: scale3d(1, 1, 1);
			  transform: scale3d(1, 1, 1);
	}
  
	30% {
	  -webkit-transform: scale3d(1.25, 0.75, 1);
			  transform: scale3d(1.25, 0.75, 1);
	}
  
	40% {
	  -webkit-transform: scale3d(0.75, 1.25, 1);
			  transform: scale3d(0.75, 1.25, 1);
	}
  
	50% {
	  -webkit-transform: scale3d(1.15, 0.85, 1);
			  transform: scale3d(1.15, 0.85, 1);
	}
  
	65% {
	  -webkit-transform: scale3d(.95, 1.05, 1);
			  transform: scale3d(.95, 1.05, 1);
	}
  
	75% {
	  -webkit-transform: scale3d(1.05, .95, 1);
			  transform: scale3d(1.05, .95, 1);
	}
  
	100% {
	  -webkit-transform: scale3d(1, 1, 1);
			  transform: scale3d(1, 1, 1);
	}
  }
  
  .rubberBand {
	-webkit-animation-name: rubberBand;
			animation-name: rubberBand;
  }